<template>
    <button class="cancel" @click="cancel"><span>{{ $t('nav.quit') }}</span></button>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";

    export default defineComponent({
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();

            const cancel = () => {
                router.push({
                    name: 'cancel',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };

            return {
                cancel
            };
        }
    });
</script>

