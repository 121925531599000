<template>
    <button class="help" @click="help"><span>{{ $t('nav.help') }}</span></button>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";

    export default defineComponent({
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();

            const help = () => {
                router.push({
                    name: 'help',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };

            return {
                help
            };
        }
    });
</script>
