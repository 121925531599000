
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import {Router, useRouter} from "vue-router";
    import ConnectionChecker from "@/helpers/ConnectionChecker";

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const router: Router = useRouter();

            const close = async () => {
                ConnectionChecker.close(router);
            };

            const cancel = () => {
                router.go(-1);
            };

            return {
                close,
                cancel
            }
        }
    });
