<template>
    <div class="view help" role="main" aria-label="help-main">
        <div class="internal nav-with-text">
            <div class="help-body">
                <nav class="top">
                    <Back/>
                    <Language/>
                </nav>

                <h1 v-html="$t('help.header')"></h1>

                <p class="desc" v-html="$t('help.description')"></p>

                <ul class="links">
                    <li><span v-on:click="terms">{{ $t('help.terms') }}</span></li>
                    <li><span v-on:click="cookies">{{ $t('help.cookie') }}</span></li>
                </ul>

                <div class="powered-by">
                    <span>{{ $t('general.powered_by')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_help";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import Back from "../components/Back.vue";
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";

    export default defineComponent({
        components: {
            Back,
            Language,
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();
            const cookies = () => {
                router.push({
                    name: 'cookie',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };

            const terms = () => {
                router.push({
                    name: 'terms',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };
            return {
                cookies,
                terms
            };
        }
    });
</script>
