<template>
    <div class="view info">
        <div class="header" role="navigation" aria-label="info-nav">
            <div class="internal nav-with-text">
                <nav class="top">
                    <Back />
                    <Help />
                    <Language/>
                </nav>

                <h1><span>{{ product.brand }} {{ product.name }}</span></h1>

                <div class="img-wrapper">
                    <div class="img">
                        <img :src="product.url" :alt="$t('info.product_image_alt', {'name': product.brand + ' ' + product.name})"/>
                    </div>
                    <div class="filters filters-left">
                        <FilterInfo
                                v-for="filter in leftFilters"
                                v-bind:key="filter.code"
                                v-bind:filter="filter"
                                v-bind:isActive="activeFilter === filter.code"
                                v-bind:filterClick="filterClick"
                        />
                    </div>
                    <div class="filters filters-right">
                        <FilterInfo
                                v-for="filter in rightFilters"
                                v-bind:key="filter.code"
                                v-bind:filter="filter"
                                v-bind:isActive="activeFilter === filter.code"
                                v-bind:filterClick="filterClick"
                        />
                    </div>
                </div>

                <div class="on-sale" v-if="product.sale_price > 0">{{ $t('info.on_sale') }}</div>
            </div>
        </div>

        <div class="main" role="main" aria-label="info-main">
            <div class="internal">
                <div class="price-form">
                    <div class="price">{{ price(product.sale_price > 0 ? product.sale_price : product.price) }}</div>
                    <div class="sale-price" v-if="product.sale_price > 0">{{ price(product.price) }}</div>
                    <div class="form">
                        <div class="minus" @click="changeCount(-1)" :aria-label="$t('info.decrease_quantity')">-</div>
                        <input type="number" v-model="count" min="0" :max="product.max && product.max < max ? product.max : max" @change="validateCount" :aria-label="$t('info.quantity')"/>
                        <div class="plus" @click="changeCount(1)" :aria-label="$t('info.increase_quantity')">+</div>
                    </div>
                </div>

                <div v-if="detail.detail && detail.detail.allergens && detail.detail.allergens.length > 0" class="allergens-standalone">
                    <div class="wrapper">
                        <div class="header">
                            {{ $t('allergens.header') }}
                        </div>
                        <div class="list">
                            <span v-for="(allergen, index) in detail.detail.allergens" v-bind:key="index">
                                {{ $t('allergens.' + allergen) }}{{ (index + 1) !== detail.detail.allergens.length ? ',' : '' }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="details">
                    <div class="control" @click="toggleDetails">{{ $t('info.product_description') }}</div>
                    <div class="panel description">
                        <div>
                            <p v-if="detail == null">
                                {{ $t('info.loading_details') }}
                            </p>

                            <p v-if="detail.detail && detail.detail.description">
                                {{ detail.detail.description }}
                            </p>
                            <p v-else-if="detail != null">
                                {{ $t('info.no_description') }}
                            </p>
                        </div>
                    </div>

                    <div class="control" @click="toggleDetails">{{ $t('info.ingredients') }}</div>
                    <div class="panel ingredients">
                        <div>
                            <p v-if="detail == null">
                                {{ $t('info.loading_details') }}
                            </p>

                            <p v-if="detail.detail && detail.detail.ingredients">
                                {{ detail.detail.ingredients }}
                            </p>
                            <p v-else-if="detail != null">
                                {{ $t('info.no_ingredients') }}
                            </p>

                            <div v-if="detail.detail && detail.detail.allergens && detail.detail.allergens.length > 0" class="allergens">
                                <div class="header">
                                    {{ $t('allergens.header') }}
                                </div>
                                <div class="list">
                                    <span v-for="(allergen, index) in detail.detail.allergens" v-bind:key="index">
                                        {{ $t('allergens.' + allergen) }}{{ (index + 1) !== detail.detail.allergens.length ? ',' : '' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="control" @click="toggleDetails">{{ $t('info.nutritional_information') }}</div>
                    <div class="panel nutrition">
                        <p v-if="detail == null">
                            {{ $t('info.loading_details') }}
                        </p>

                        <div v-if="detail.detail && detail.detail.nutrition && detail.detail.nutrition_fields && detail.detail.nutrition_fields.length > 0">
                            <table class="nutrition">
                                <tr>
                                    <th>{{ $t('info.nutrition.values') }}</th>
                                    <th v-for="(field, index) in detail.detail.nutrition_fields" v-bind:key="index">
                                        {{ $t('info.nutrition.' + field) }}
                                    </th>
                                </tr>
                                <tr v-for="(fields, index) in detail.detail.nutrition" v-bind:key="index">
                                    <td>{{ fields.name }}</td>
                                    <td>{{ fields.field_0 }}</td>
                                    <td>{{ fields.field_1 }}</td>
                                </tr>
                            </table>
                        </div>
                        <p v-else-if="detail != null">
                            {{ $t('info.no_nutrition') }}
                        </p>
                    </div>
                </div>

                <div class="add-to-basket">
                    <button v-on:click="addToBasket">
                        <span v-if="hasCurrent" v-html="$tc('info.update_basket', count, {count})"></span>
                        <span v-else-if="count > 0" v-html="$tc('info.add_to_basket', count, {count})"></span>
                        <span v-else v-html="$t('info.return')"></span>
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_info";
</style>

<script lang="ts">
    import { defineComponent, ref, reactive } from 'vue';
    import Back from "@/components/Back.vue";
    import Help from "@/components/Help.vue";
    import Language from "@/components/Language.vue";
    import Products, {Product} from "@/stores/Products";
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";
    import Basket, {BasketItem} from "@/stores/Basket";
    import Currency from "@/helpers/Currency";
    import ProductInfo, {ParsedProductDetailResponse, ProductDetailResponse} from "@/helpers/ProductInfo";
    import FilterInfo from "@/components/FilterInfo.vue";

    interface Detail {
        detail: ParsedProductDetailResponse | null;
    }

    export default defineComponent({
        components: {
            Back,
            Help,
            Language,
            FilterInfo
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();
            const podSearchId: string = route.params.podSearchId.toString();
            const productId: number = parseInt(route.params.productId.toString());
            const product: Product = Products.getProduct(productId);

            const currentBasket: BasketItem | null = Basket.getFromBasket(product);
            const count = ref(currentBasket ? currentBasket.count : 1);
            const maxPerProduct = Basket.getMaxItems(product);
            const max = product.max && product.max < maxPerProduct ? product.max : (maxPerProduct > 0 ? maxPerProduct : 1);
            const activeFilter = ref('');

            const detail = reactive({
                detail: null,
            } as Detail);
            ProductInfo.loadInfo(podSearchId, productId).then((response: ProductDetailResponse) => {
                detail.detail = response.data;
            });

            const validateCount = () => {
                if (!parseInt(count.value.toString())) {
                    count.value = 0;
                }

                if (count.value > max) {
                    count.value = max;
                }
                if (count.value < 0) {
                    count.value = 0;
                }
            };

            const changeCount = (change: number) => {
                count.value += change;
                validateCount();
            };

            const addToBasket = () => {
                const isValid: boolean = Basket.addToBasket(product, parseInt(count.value.toString()), false, router, podSearchId);
                if (isValid) {
                    router.go(-1);
                }
            };

            const filterClick = (code: string) => {
                activeFilter.value = code === activeFilter.value ? '' : code;
            };

            const toggleDetails = (e: MouseEvent) => {
                const target: HTMLElement = e.target as HTMLElement;
                target.classList.toggle('active');
                const panel: HTMLElement = target.nextElementSibling as HTMLElement;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = "";
                }
                else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            };

            return {
                podSearchId,
                productId,
                product,
                hasCurrent: currentBasket !== null,
                price: Currency.price,

                count,
                max,
                changeCount,
                validateCount,

                leftFilters: ProductInfo.getFilters(product.filters, true),
                rightFilters: ProductInfo.getFilters(product.filters, false),
                activeFilter,
                filterClick,

                addToBasket,
                detail,
                toggleDetails
            }
        }
    });
</script>
