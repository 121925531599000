<template>
  <div class="product-wrapper">
      <router-link :to="{ name: 'info', params: { podSearchId: $route.params.podSearchId, productId: product.id }}" :class="count(product.id) > 0 ? 'active' : ''">
        <span class="count">{{ count(product.id) }}</span>
        <span class="product">
          <strong>
            <img :src="product.url" :alt="$t('info.product_image_alt', {'name': product.brand + ' ' + product.name})"/>
          </strong>
          <span>
            <strong>{{ product.brand }}</strong> {{ product.name }}
          </span>
          <em :class="product.sale_price > 0 ? 'has-sale' : ''">
            <span class="price">{{ price(product.sale_price > 0 ? product.sale_price : product.price) }}</span>
            <span class="sale-price" v-if="product.sale_price > 0">{{ price(product.price) }}</span>
          </em>
        </span>
      </router-link>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Currency from "@/helpers/Currency";
  import {BasketItem} from "@/stores/Basket";

  export default defineComponent({
    props: {
      product: Object,
      basket: Object
    },
    setup: (props: any) => {
      const count = (productId: number): number => {
        for (let i = 0; i < props.basket.length; i ++) {
          const item: BasketItem = props.basket[i] as BasketItem;
          if (item.product.id === productId) {
            return item.count;
          }
        }

        return 0;
      };

      return {
        price: Currency.price,
        count
      };
    }
  });
</script>

<style lang="scss" scoped>
  div {
    font-style: italic;
  }
</style>
