
    import { defineComponent } from 'vue';
    import Help from "@/components/Help.vue";
    import Language from "@/components/Language.vue";
    import Products from "@/stores/Products";
    import Back from "@/components/Back.vue";
    import FilterTile from "@/components/FilterTile.vue";
    import {Router, useRouter} from "vue-router";

    export default defineComponent({
        components: {
            Help,
            Language,
            Back,
            FilterTile
        },
        setup: () => {
            const router: Router = useRouter();

            const reset = () => {
                Products.setActiveFilters([]);
            };

            const done = () => {
                router.go(-1);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            return {
                products: Products.state,
                reset,
                done
            }
        }
    });
