<template>
    <div class="view error warning" role="main" aria-label="basket-item-limit-main">
        <div class="internal nav-with-text">
            <div class="error-text">
                <nav class="top">
                    <Language/>
                </nav>

                <h1 v-html="$t('error.basket_item_limit.header')"></h1>

                <p class="desc" v-html="$tc('error.basket_item_limit.description', max, {count : max})"></p>

                <div class="error-icon">
                  <div class="icon closed"><em></em></div>
                </div>

                <div class="button">
                    <button class="close" @click="close">{{ $t('error.basket_item_limit.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../../styles/views/_error";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import {Router, useRouter} from "vue-router";
    import Basket from "@/stores/Basket";
    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const router: Router = useRouter();

            const close = () => {
                router.go(-1);
            };

            return {
                close,
                max: Basket.state.maxItems,
            }
        }
    });
</script>