
  import { defineComponent, ref } from 'vue';
  import Currency from "@/helpers/Currency";
  import Basket, {BasketItem} from "@/stores/Basket";
  import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";

  export default defineComponent({
    props: {
      item: Object,
      ignoreLimit: String,
    },
    setup(props: any) {
      const item: BasketItem = props.item as BasketItem;
      const count = ref(item.count || 1);
      const maxPerProduct = Basket.getMaxItems(props.ignoreLimit ? null : item.product);
      const max = item.product.max && item.product.max < maxPerProduct ? item.product.max : (maxPerProduct > 0 ? maxPerProduct : 1);
      const route: RouteLocationNormalizedLoaded = useRoute();
      const router: Router = useRouter();
      const podSearchId: string = route.params.podSearchId.toString();

      const validateCount = () => {
        if (!parseInt(count.value.toString())) {
          count.value = 0;
        }

        if (count.value > max) {
          count.value = max;
        }
        if (count.value < 0) {
          count.value = 0;
        }

        Basket.addToBasket(item.product, count.value, true, router, podSearchId);
      };

      const changeCount = (change: number) => {
        count.value += change;
        validateCount();
      };

      return {
        price: Currency.price,
        product: item.product,
        count: count,
        max,
        validateCount,
        changeCount,
      }
    }
  });
