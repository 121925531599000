
  import { defineComponent } from 'vue';
  import Currency from "@/helpers/Currency";
  import {BasketItem} from "@/stores/Basket";

  export default defineComponent({
    props: {
      product: Object,
      basket: Object
    },
    setup: (props: any) => {
      const count = (productId: number): number => {
        for (let i = 0; i < props.basket.length; i ++) {
          const item: BasketItem = props.basket[i] as BasketItem;
          if (item.product.id === productId) {
            return item.count;
          }
        }

        return 0;
      };

      return {
        price: Currency.price,
        count
      };
    }
  });
