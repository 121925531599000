<template>
    <div class="view list" id="sticky-holder">
        <div class="header" role="navigation" aria-label="list-nav">
            <div class="internal nav-with-text">
                <nav class="top">
                    <Cancel />
                    <Help />
                    <Language/>
                </nav>

                <h1>{{ $t('list.header') }}</h1>

                <div class="search-wrapper">
                    <div class="search">
                        <button class="filter" v-if="products.filters.length > 0" :class="products.activeFilters.length > 0 ? 'active' : ''" @click="openFilters"><span>{{ $t('list.filter') }}</span></button>
                        <ul class="quick-links" v-if="products.activeQuickLink === ''">
                            <li v-for="link in products.quickLinks" :key="link" @click="setQuickLink(link)">
                                <button><span>{{ $t('quickLinks.' + link)}}</span></button>
                            </li>
                        </ul>
                        <ul class="quick-links" v-else>
                            <li class="active" @click="removeQuickLink">
                                <button><span>{{ $t('quickLinks.' + products.activeQuickLink)}}</span></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="main" role="main" aria-label="list-main">
            <div class="internal">
                <div class="products" :class="products.activeProducts.length === 1 ? 'single' : ''" v-if="products.activeProducts.length > 0">
                    <ProductTile
                            v-for="product in products.activeProducts"
                            v-bind:key="product.id"
                            v-bind:product="product"
                            v-bind:basket="basket.basket"
                    />
                </div>

                <div class="no-products" v-else>
                    <strong>{{ $t('list.no_products_header') }}</strong>
                    <span>{{ $t('list.no_products_description') }}</span>
                </div>

                <div class="footer" :class="basket.count > 0 ? 'has-checkout' : ''">
                    <div class="powered-by">
                        <span>{{ $t('general.powered_by')}}</span>
                    </div>
                </div>

                <div v-if="basket.count > 0" class="checkout">
                    <router-link :to="{ name: 'checkout', params: { podSearchId: $route.params.podSearchId }}">
                        <span v-html="$tc('list.checkout', basket.count, {count: basket.count, total: price(basket.total)})"></span>
                    </router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_list";
</style>

<script lang="ts">
    import { defineComponent, onMounted } from 'vue';
    import ProductTile from "@/components/ProductTile.vue";
    import Language from "@/components/Language.vue";
    import Help from "@/components/Help.vue";
    import Cancel from "@/components/Cancel.vue";
    import Products from "@/stores/Products";
    import Basket from "@/stores/Basket";
    import Currency from "@/helpers/Currency";
    import {useRouter, useRoute, RouteLocationNormalizedLoaded, Router} from 'vue-router';

    export default defineComponent({
        components: {
            ProductTile,
            Language,
            Help,
            Cancel,
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();

            Basket.clean();

            const setQuickLink = (quickLink: string) => {
                Products.setActiveQuickLink(quickLink);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            const removeQuickLink = () => {
                Products.setActiveQuickLink('');
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            const openFilters = () => {
                router.push({
                    name: 'filters',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };

            onMounted(() => {
                const header: HTMLElement = document.getElementById('sticky-holder') as HTMLElement;
                const bar: HTMLElement = document.getElementsByClassName('search-wrapper')[0] as HTMLElement;
                const stickyPos: number = bar.offsetTop;
                window.onscroll = () => {
                    if (window.pageYOffset > stickyPos) {
                        header.classList.add('sticky');
                    } else {
                        header.classList.remove('sticky');
                    }
                };
            });

            return {
                products: Products.state,
                basket: Basket.state,
                price: Currency.price,
                setQuickLink,
                removeQuickLink,
                openFilters
            };
        },
    });
</script>
