
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import Back from "../components/Back.vue";

    export default defineComponent({
        components: {
            Back,
            Language,
        },
        setup: () => {
            return {};
        }
    });
