
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const close = () => {
                window.location.href = '/';
            };

            return {
                close
            }
        }
    });
