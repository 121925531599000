import { defineComponent } from "vue";

const hide = () => {
    const preLoad: HTMLElement | null = document.getElementById('pre-load');
    if (preLoad !== null) {
        preLoad.style.display = 'none';
    }
};

export default defineComponent({
    hide
});
