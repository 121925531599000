<template>
    <div class="view error warning" role="main" aria-label="cancel-main">
        <div class="internal nav-with-text">

            <div class="error-text">
                <nav class="top">
                    <Language/>
                </nav>

                <h1 v-html="$t('error.cancel.header')"></h1>

                <p class="desc" v-html="$t('error.cancel.description')"></p>

                <div class="error-icon">
                  <div class="icon cancel"><em></em></div>
                </div>

                <div class="button buttons">
                    <button class="cancel light" @click="cancel">{{ $t('error.cancel.cancel') }}</button>
                    <button class="close" @click="close">{{ $t('error.cancel.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../../styles/views/_error";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import {Router, useRouter} from "vue-router";
    import ConnectionChecker from "@/helpers/ConnectionChecker";

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const router: Router = useRouter();

            const close = async () => {
                ConnectionChecker.close(router);
            };

            const cancel = () => {
                router.go(-1);
            };

            return {
                close,
                cancel
            }
        }
    });
</script>