
    import { defineComponent, ref, reactive } from 'vue';
    import Back from "@/components/Back.vue";
    import Help from "@/components/Help.vue";
    import Language from "@/components/Language.vue";
    import Products, {Product} from "@/stores/Products";
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";
    import Basket, {BasketItem} from "@/stores/Basket";
    import Currency from "@/helpers/Currency";
    import ProductInfo, {ParsedProductDetailResponse, ProductDetailResponse} from "@/helpers/ProductInfo";
    import FilterInfo from "@/components/FilterInfo.vue";

    interface Detail {
        detail: ParsedProductDetailResponse | null;
    }

    export default defineComponent({
        components: {
            Back,
            Help,
            Language,
            FilterInfo
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();
            const podSearchId: string = route.params.podSearchId.toString();
            const productId: number = parseInt(route.params.productId.toString());
            const product: Product = Products.getProduct(productId);

            const currentBasket: BasketItem | null = Basket.getFromBasket(product);
            const count = ref(currentBasket ? currentBasket.count : 1);
            const maxPerProduct = Basket.getMaxItems(product);
            const max = product.max && product.max < maxPerProduct ? product.max : (maxPerProduct > 0 ? maxPerProduct : 1);
            const activeFilter = ref('');

            const detail = reactive({
                detail: null,
            } as Detail);
            ProductInfo.loadInfo(podSearchId, productId).then((response: ProductDetailResponse) => {
                detail.detail = response.data;
            });

            const validateCount = () => {
                if (!parseInt(count.value.toString())) {
                    count.value = 0;
                }

                if (count.value > max) {
                    count.value = max;
                }
                if (count.value < 0) {
                    count.value = 0;
                }
            };

            const changeCount = (change: number) => {
                count.value += change;
                validateCount();
            };

            const addToBasket = () => {
                const isValid: boolean = Basket.addToBasket(product, parseInt(count.value.toString()), false, router, podSearchId);
                if (isValid) {
                    router.go(-1);
                }
            };

            const filterClick = (code: string) => {
                activeFilter.value = code === activeFilter.value ? '' : code;
            };

            const toggleDetails = (e: MouseEvent) => {
                const target: HTMLElement = e.target as HTMLElement;
                target.classList.toggle('active');
                const panel: HTMLElement = target.nextElementSibling as HTMLElement;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = "";
                }
                else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                }
            };

            return {
                podSearchId,
                productId,
                product,
                hasCurrent: currentBasket !== null,
                price: Currency.price,

                count,
                max,
                changeCount,
                validateCount,

                leftFilters: ProductInfo.getFilters(product.filters, true),
                rightFilters: ProductInfo.getFilters(product.filters, false),
                activeFilter,
                filterClick,

                addToBasket,
                detail,
                toggleDetails
            }
        }
    });
