<template>
    <div class="view filters" role="main" aria-label="filter-main">
        <div class="internal nav-with-text">
            <nav class="top">
                <Back />
                <Help />
                <Language/>
            </nav>

            <h1>{{ $t('filters.header') }}</h1>

            <p class="desc">
                {{ $t('filters.description') }}
            </p>

            <div class="filter-list">
                <FilterTile
                        v-for="filter in products.filters"
                        v-bind:key="filter.code"
                        v-bind:filter="filter"
                        v-bind:isActive="products.activeFilters.includes(filter.code)"
                />
            </div>

            <p class="disclaimer">
                {{ $t('filters.disclaimer') }}
            </p>

            <div class="buttons">
                <button class="reset" @click="reset">{{ $t('filters.reset') }}</button>
                <button class="done" @click="done">{{ $t('filters.done') }}</button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_filters";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Help from "@/components/Help.vue";
    import Language from "@/components/Language.vue";
    import Products from "@/stores/Products";
    import Back from "@/components/Back.vue";
    import FilterTile from "@/components/FilterTile.vue";
    import {Router, useRouter} from "vue-router";

    export default defineComponent({
        components: {
            Help,
            Language,
            Back,
            FilterTile
        },
        setup: () => {
            const router: Router = useRouter();

            const reset = () => {
                Products.setActiveFilters([]);
            };

            const done = () => {
                router.go(-1);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            return {
                products: Products.state,
                reset,
                done
            }
        }
    });
</script>
