<template>
  <div class="basket-item">
    <div class="title">
      {{ product.brand }} {{ product.name }}
    </div>
    <div class="img">
      <img :src="product.url" :alt="$t('info.product_image_alt', {'name': product.brand + ' ' + product.name})"/>
    </div>
    <div class="item-price">
      <span class="price">{{ price(product.sale_price > 0 ? product.sale_price : product.price) }}</span>
      <span class="sale-price" v-if="product.sale_price > 0">{{ price(product.price) }}</span>
    </div>
    <div class="quantity">
      <div class="wrapper">
        <div class="minus" @click="changeCount(-1)">-</div>
        <input type="number" v-model="count" min="0" :max="product.max && product.max < max ? product.max : max" @change="validateCount"/>
        <div class="plus" @click="changeCount(1)">+</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import Currency from "@/helpers/Currency";
  import Basket, {BasketItem} from "@/stores/Basket";
  import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";

  export default defineComponent({
    props: {
      item: Object,
      ignoreLimit: String,
    },
    setup(props: any) {
      const item: BasketItem = props.item as BasketItem;
      const count = ref(item.count || 1);
      const maxPerProduct = Basket.getMaxItems(props.ignoreLimit ? null : item.product);
      const max = item.product.max && item.product.max < maxPerProduct ? item.product.max : (maxPerProduct > 0 ? maxPerProduct : 1);
      const route: RouteLocationNormalizedLoaded = useRoute();
      const router: Router = useRouter();
      const podSearchId: string = route.params.podSearchId.toString();

      const validateCount = () => {
        if (!parseInt(count.value.toString())) {
          count.value = 0;
        }

        if (count.value > max) {
          count.value = max;
        }
        if (count.value < 0) {
          count.value = 0;
        }

        Basket.addToBasket(item.product, count.value, true, router, podSearchId);
      };

      const changeCount = (change: number) => {
        count.value += change;
        validateCount();
      };

      return {
        price: Currency.price,
        product: item.product,
        count: count,
        max,
        validateCount,
        changeCount,
      }
    }
  });
</script>