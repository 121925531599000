
    import { defineComponent, onMounted } from 'vue';
    import ProductTile from "@/components/ProductTile.vue";
    import Language from "@/components/Language.vue";
    import Help from "@/components/Help.vue";
    import Cancel from "@/components/Cancel.vue";
    import Products from "@/stores/Products";
    import Basket from "@/stores/Basket";
    import Currency from "@/helpers/Currency";
    import {useRouter, useRoute, RouteLocationNormalizedLoaded, Router} from 'vue-router';

    export default defineComponent({
        components: {
            ProductTile,
            Language,
            Help,
            Cancel,
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();

            Basket.clean();

            const setQuickLink = (quickLink: string) => {
                Products.setActiveQuickLink(quickLink);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            const removeQuickLink = () => {
                Products.setActiveQuickLink('');
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            };

            const openFilters = () => {
                router.push({
                    name: 'filters',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };

            onMounted(() => {
                const header: HTMLElement = document.getElementById('sticky-holder') as HTMLElement;
                const bar: HTMLElement = document.getElementsByClassName('search-wrapper')[0] as HTMLElement;
                const stickyPos: number = bar.offsetTop;
                window.onscroll = () => {
                    if (window.pageYOffset > stickyPos) {
                        header.classList.add('sticky');
                    } else {
                        header.classList.remove('sticky');
                    }
                };
            });

            return {
                products: Products.state,
                basket: Basket.state,
                price: Currency.price,
                setQuickLink,
                removeQuickLink,
                openFilters
            };
        },
    });
