<template>
    <button class="back" @click="back"><span>{{ $t('nav.back') }}</span></button>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";

    export default defineComponent({
        setup: () => {
            const router: Router = useRouter();

            const back = () => {
                router.go(-1);
            };

            return {
                back
            };
        }
    });
</script>

