<template>
    <div class="view terms" role="main" aria-label="terms-main">
        <div class="internal nav-with-text">
            <div class="terms-body">
                <nav class="top">
                    <Back/>
                    <Language/>
                </nav>

                <h1 v-html="$t('cookie.header')"></h1>

                <div class="desc">
                    <div v-html="$t('cookie.description_1')"></div>
                    <div v-html="$t('cookie.description_2')"></div>
                    <div v-html="$t('cookie.description_3')"></div>
                    <div v-html="$t('cookie.description_4')"></div>
                    <div v-html="$t('cookie.description_5')"></div>
                </div>

                <div class="powered-by">
                    <span>{{ $t('general.powered_by')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_terms";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import Back from "../components/Back.vue";

    export default defineComponent({
        components: {
            Back,
            Language,
        },
        setup: () => {
            return {};
        }
    });
</script>
