<template>
    <div class="filter" :class="isActive ? 'active' : ''" @click="filterClick(filter.code)">
        <img :src="filter.url" :alt="$t('filters.' + filter.code)"/>
        <em></em>
        <span><span>{{ $t('filters.' + filter.code) }}</span></span>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            filter: Object,
            isActive: Boolean,
            filterClick: Function,
        }
    });
</script>
