
    import { defineComponent, onMounted } from 'vue';
    import Language from "@/components/Language.vue";
    import Basket from "@/stores/Basket";
    import axios from "axios";
    import env from "@/env";
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";
    import ConnectionChecker from "@/helpers/ConnectionChecker";

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            onMounted(async () => {
                const route: RouteLocationNormalizedLoaded = useRoute();
                const podSearchId: string = route.params.podSearchId.toString();
                const router: Router = useRouter();

                try {
                    if (!ConnectionChecker.isDemo()) {
                        ConnectionChecker.stopPingCheck();

                        await axios.post(env.API_URL + '/' + podSearchId + '/order', {
                            '_token': Basket.getCSRF(),
                            'order': Basket.getOrder(),
                        });
                    }
                    else {
                        await new Promise(r => setTimeout(r, 2000));
                    }

                    router.push({
                        name: 'payment',
                        params: {
                            id: podSearchId,
                        }
                    });
                } catch (error) {
                    console.log(error.response.data);

                    throw error;
                }
            });
        }
    });
