import { defineComponent, reactive, readonly } from "vue";
import { Product } from "@/stores/Products";
import {Router} from "vue-router";

export interface BasketItem {
    product: Product;
    count: number;
}

interface Basket {
    basket: BasketItem[];
    total: number;
    count: number;
    maxItems: number;
    maxCost: number;
}

interface OrderItem {
    productId: number;
    quantity: number;
}

interface Total {
    total: number;
    count: number;
}

const MAX_ITEMS = 3;
const MAX_COST = 30 * 100;

const state = reactive({
    basket: [],
    total: 0,
    count: 0,
    maxItems: MAX_ITEMS,
    maxCost: MAX_COST,
} as Basket);

let _csrf = '';

const triggerTotalLimit = (router: Router | null, podSearchId: number): boolean => {
    if (router !== null) {
        router.push({
            name: 'basket_total_limit',
            params: {
                id: podSearchId,
            }
        });

        return false;
    }

    return true;
};

const triggerItemLimit = (router: Router | null, podSearchId: number): boolean => {
    if (router !== null) {
        router.push({
            name: 'basket_item_limit',
            params: {
                id: podSearchId,
            }
        });

        return false;
    }

    return true;
};

const recalculateTotal = (basket: BasketItem[] | null = null): Total => {
    let useStateBasket = false;
    if (basket === null) {
        useStateBasket = true;
        basket = state.basket;
    }

    let total = 0;
    let count = 0;
    basket.map((item: BasketItem) => {
        total += (item.product.sale_price > 0 ? item.product.sale_price : item.product.price) * item.count;
        count += item.count;
    });

    if (useStateBasket) {
        state.basket = basket;
        state.total = total;
        state.count = count;
    }

    return {
        total,
        count,
    };
};

const addToBasket = (product: Product, count = 1, keep = false, router: Router | null = null, podSearchId = 0): boolean => {
    if (typeof count !== 'number' || count < 0) {
        count = 0;
    }

    const basket = JSON.parse(JSON.stringify(state.basket));

    for (let i = basket.length - 1; i >= 0 ; i --) {
        if (basket[i].product.id === product.id) {
            if (count === 0 && !keep) {
                basket.splice(i, 1);
            }
            else {
                basket[i].count = count;
            }

            const totals: Total = recalculateTotal(basket);
            if (totals.total > state.maxCost) {
                return triggerTotalLimit(router, podSearchId);
            }
            if (totals.count > state.maxItems) {
                return triggerItemLimit(router, podSearchId);
            }

            state.basket = basket;
            recalculateTotal();

            return true;
        }
    }

    basket.push({
        product: product,
        count: count,
    });
    const totals: Total = recalculateTotal(basket);
    if (totals.total > state.maxCost) {
        return triggerTotalLimit(router, podSearchId);
    }
    if (totals.count > state.maxItems) {
        return triggerItemLimit(router, podSearchId);
    }

    state.basket = basket;
    recalculateTotal();

    return true;
};

const getFromBasket = (product: Product): BasketItem | null => {
    for (let i = 0; i < state.basket.length; i ++) {
        if (state.basket[i].product.id === product.id) {
            return state.basket[i];
        }
    }

    return null;
};

const getOrder = (): OrderItem[] => {
    const order: OrderItem[] = [];

    for (const item of state.basket) {
        order.push({
            productId: item.product.id,
            quantity: item.count
        });
    }

    return order;
};

const clean = () => {
    for (let i = state.basket.length - 1; i >= 0 ; i --) {
        if (state.basket[i].count <= 0) {
            state.basket.splice(i, 1);
        }
    }

    return recalculateTotal();
};

const setCSRF = (csrf: string): void => {
    _csrf = csrf;
};

const getCSRF = (): string => {
    return _csrf;
};

const setMaxItems = (max: number): void => {
    state.maxItems = max;
};

const getMaxItems = (product: Product|null = null): number => {
    let total = 0;

    if (product !== null) {
        for (let i = 0; i < state.basket.length; i++) {
            if (state.basket[i].product.id !== product.id) {
                total = state.basket[i].count;
            }
        }
    }

    return state.maxItems - total;
};

const setMaxCost = (max: number): void => {
    state.maxCost = max;
};

export default defineComponent({
    state: readonly(state),
    addToBasket,
    getFromBasket,
    clean,
    setCSRF,
    getCSRF,
    getOrder,
    setMaxItems,
    getMaxItems,
    setMaxCost,
});
