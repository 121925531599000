<template>
    <div class="view checkout" role="main" aria-label="checkout-main">
        <div class="internal nav-with-text">
            <nav class="top">
                <Back />
                <Help />
                <Language/>
            </nav>

            <h1>{{ $t('checkout.header') }}</h1>

            <div class="basket-wrapper">
                <div class="basket">
                    <BasketTile
                            v-for="(item) in basket.basket"
                            :key="item.product.id"
                            :item="item"
                            :ignoreLimit="true"
                    />
                </div>
                <div class="total">
                    <span v-html="$t('checkout.total', {total: price(basket.total)})"></span>
                </div>
            </div>

            <p class="disclaimer">
                {{ $t('checkout.disclaimer') }}
            </p>

            <div class="button" v-if="basket.count > 0">
                <router-link :to="{ name: 'payment_loader', params: { podSearchId: podSearchId }}">
                    <span v-html="$t('checkout.pay_now', {total: price(basket.total)})"></span>
                </router-link>
            </div>
            <div class="button" v-else>
                <span>
                    <span v-html="$t('checkout.return')"></span>
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_checkout";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import BasketTile from "@/components/BasketTile.vue";
    import Language from "@/components/Language.vue";
    import Help from "@/components/Help.vue";
    import Basket from "@/stores/Basket";
    import Currency from "@/helpers/Currency";
    import {RouteLocationNormalizedLoaded, useRoute} from "vue-router";
    import Back from "@/components/Back.vue";

    export default defineComponent({
        components: {
            BasketTile,
            Back,
            Language,
            Help,
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const podSearchId: string = route.params.podSearchId.toString();

            return {
                basket: Basket.state,
                price: Currency.price,
                podSearchId
            };
        },
    });
</script>
