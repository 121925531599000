
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import {Router, useRouter} from "vue-router";
    import Basket from "@/stores/Basket";
    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const router: Router = useRouter();

            const close = () => {
                router.go(-1);
            };

            return {
                close,
                max: Basket.state.maxItems,
            }
        }
    });
