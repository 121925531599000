<template>
    <div class="view error warning">
        <div class="internal nav-with-text" role="main" aria-label="payment-main">
            <div class="error-text">
                <nav class="top">
                    <Language/>
                </nav>

                <h1 v-html="$t('payment.header')"></h1>

                <p class="desc" v-html="$t('payment.description')"></p>

                <div class="error-icon">
                  <div class="icon payment"><em></em></div>
                </div>

                <div class="footer">
                    <div class="powered-by">
                        <span>{{ $t('general.powered_by')}}</span>
                    </div>
                </div>

                <div class="button">
                    <button class="close" @click="close">{{ $t('payment.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_error";
</style>

<script lang="ts">
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const close = () => {
                window.location.href = '/';
            };

            return {
                close
            }
        }
    });
</script>