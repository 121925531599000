<template>
  <div class="filter" :class="isActive ? 'active' : ''" @click="toggleFilter(filter.code)">
      <div class="image-wrapper"><img :src="filter.url" :alt="$t('filters.' + filter.code)"></div>
      <span>{{ $t('filters.' + filter.code) }}</span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Products from "@/stores/Products";

  export default defineComponent({
    props: {
        filter: Object,
        isActive: Boolean,
    },
    setup: () => {
        const toggleFilter = (code: string) => {
            const active: string[] = [...Products.state.activeFilters];
            let hasToggled = false;
            for (let i = 0; i < active.length; i ++) {
                if (active[i] === code) {
                    active.splice(i, 1);
                    hasToggled = true;
                }
            }

            if (!hasToggled) {
                active.push(code);
            }

            Products.setActiveFilters(active);
        };

        return {
            products: Products.state,
            toggleFilter,
        };
    }
  });
</script>

<style lang="scss" scoped>
  div {
    font-style: italic;
  }
</style>
