
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";
    import Back from "../components/Back.vue";
    import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router";

    export default defineComponent({
        components: {
            Back,
            Language,
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const router: Router = useRouter();
            const cookies = () => {
                router.push({
                    name: 'cookie',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };

            const terms = () => {
                router.push({
                    name: 'terms',
                    params: {
                        id: route.params.podSearchId,
                    }
                });
            };
            return {
                cookies,
                terms
            };
        }
    });
