
    import { defineComponent } from 'vue';
    import Language from "@/components/Language.vue";

    export default defineComponent({
        props: {
            reason: String
        },
        components: {
            Language,
        },
        setup: (props) => {
            let langGroup = "connection_failed";
            let icon = "failed";
            let warningClass = "";

            if (props.reason === "invalid_site_id") {
                langGroup = "no_pod";
                icon = "lost";
            }
            else if (props.reason === "site_inactive") {
                langGroup = "no_pod";
                icon = "lost";
            }
            else if (props.reason === "failed_to_establish_connection") {
                langGroup = "no_pod";
                icon = "lost";
            }
            else if (props.reason === "unexpected_site_requested") {
                langGroup = "no_pod";
                icon = "lost";
            }
            else if (props.reason === "other_connection_active") {
                langGroup = "connection_in_use";
            }
            else if (props.reason === "no_existing_connection") {
                langGroup = "connection_failed";
            }
            else if (props.reason === "pod_in_use") {
                langGroup = "pod_in_use";
            }
            else if (props.reason === "pod_not_active") {
                langGroup = "pod_not_active";
            }

            else if (props.reason === "failed") {
                langGroup = "connection_failed";
            }
            else if (props.reason === "closed") {
                langGroup = "connection_closed";
                icon = "closed";
                warningClass = "warning";
            }
            else if (props.reason === "lost") {
                langGroup = "connection_lost";
                icon = "lost";
            }
            else if (props.reason === "no_pod") {
                langGroup = "no_pod";
                icon = "lost";
            }

            const close = () => {
                window.location.href = '/';
            };

            return {
                close,
                langGroup,
                icon,
                warningClass
            };
        }
    });
