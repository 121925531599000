import {defineComponent} from "vue";
import axios, { AxiosResponse } from 'axios';
import Products, {Filter} from "@/stores/Products";
import env from "@/env";

export interface ProductDetailResponse extends AxiosResponse {
    data: ParsedProductDetailResponse;
}

export interface ParsedProductDetailResponse {
    id: number;
    description: string;
}

const loadInfo = async (podSearchId: string, productId: number): Promise<ProductDetailResponse> => {
    try {
        if (podSearchId === 'demo') {
            return await axios.get('/demo/product-' + productId + '.json') as ProductDetailResponse;
        }
        else {
            return await axios.get(env.API_URL + '/' + podSearchId + '/products/' + productId) as ProductDetailResponse;
        }
    }
    catch (error) {
        console.log(error.response.data);

        throw error;
    }
};

const getFilters = (currentFilters: string[], isLeft: boolean): Filter[] => {
    const result: Filter[] = [];
    const allFilters: Filter[] = Products.state.filters;
    let found = 0;

    for (let i = 0; i < allFilters.length; i ++) {
        if (currentFilters.includes(allFilters[i].code)) {
            found ++;
            if (found % 2 === (isLeft ? 1 : 0)) {
                result.push(allFilters[i]);
            }
        }
    }

    return result;
};

export default defineComponent({
    loadInfo,
    getFilters
});
