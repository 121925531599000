<template>
    <div class="view loading" role="main" aria-label="loading-main">
        <div class="internal">
            <div class="loading-icon">
                <div class="icon"><span></span><strong></strong><em></em></div>
            </div>
            <div class="loading-text">
                <nav class="top">
                    <Language/>
                </nav>

                <div class="header">
                    <h1>{{ $t('loading.connecting') }}</h1>
                    <div class="location" v-if="address.address1">
                        <strong>{{ address.address1 }}</strong>
                        <span>{{ address.address2 }}</span>
                    </div>
                </div>

                <div class="footer">
                    <div class="powered-by">
                        <span>{{ $t('general.powered_by')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/views/_loading";
</style>

<script lang="ts">
    import { defineComponent, onMounted, reactive } from 'vue';
    import {useRouter, useRoute, RouteLocationNormalizedLoaded, Router} from 'vue-router';
    import Products from "@/stores/Products";
    import Language from "@/components/Language.vue";
    import ConnectionChecker from "@/helpers/ConnectionChecker";
    import PreLoad from "@/helpers/PreLoad";
    import axios, {AxiosResponse} from "axios";
    import env from "@/env";
    import Basket from "@/stores/Basket";

    interface Address {
        address1: string | null;
        address2: string | null;
    }

    interface DetailsResponse {
        company: string;
        location: string;
        address: string;
        max_per_order: number;
    }

    interface ConnectResponse {
        is_valid: boolean;
        csrf_token: string;
        response: ConnectResponseData;
    }

    interface ConnectResponseData {
        status: string;
    }

    export default defineComponent({
        components: {
            Language,
        },
        setup: () => {
            const address = reactive({
                address1: null,
                address2: null,
            } as Address);

            onMounted(async () => {
                PreLoad.hide();

                const route: RouteLocationNormalizedLoaded = useRoute();
                const router: Router = useRouter();
                const podSearchId: string = route.params.podSearchId.toString();
                ConnectionChecker.setPodId(podSearchId);

                let details: AxiosResponse;
                if (!ConnectionChecker.isDemo()) {
                    try {
                        details = await axios.get(env.API_URL + '/' + podSearchId + '/details');
                    } catch (e) {
                        console.log(e);
                        router.push({
                            name: 'connection_error',
                            params: {
                                podSearchId: podSearchId,
                                reason: "no_pod"
                            }
                        });
                        return;
                    }

                    const detailsResponse: DetailsResponse = details.data as DetailsResponse;
                    address.address1 = detailsResponse.company + ' ' + detailsResponse.location;
                    address.address2 = detailsResponse.address;
                    Basket.setMaxItems(detailsResponse.max_per_order);

                    let connect: AxiosResponse;
                    try {
                        connect = await axios.get(env.API_URL + '/' + podSearchId + '/connect');
                    } catch (e) {
                        console.log(e);
                        router.push({
                            name: 'connection_error',
                            params: {
                                podSearchId: podSearchId,
                                reason: e.response && e.response.data ? e.response.data.error : '',
                            },
                        });
                        console.log(e.response.data);
                        return;
                    }

                    const connectResponse: ConnectResponse = connect.data as ConnectResponse;
                    if (!connectResponse.is_valid) {
                        router.push({
                            name: 'connection_error',
                            params: {
                                podSearchId: podSearchId,
                                reason: connectResponse.response.status ?? 'failed',
                            },
                        });
                        return;
                    }
                    Basket.setCSRF(connectResponse.csrf_token);

                    Products.load(podSearchId).then(() => {
                        ConnectionChecker.start(router, podSearchId);
                        router.push({
                            name: 'list',
                            params: {
                                id: podSearchId,
                            }
                        });
                    }).catch(() => {
                        ConnectionChecker.failed(router, podSearchId);
                    });
                } else {
                    Basket.setMaxItems(3);

                    Products.load(podSearchId).then(() => {
                        ConnectionChecker.start(router, podSearchId);
                        window.setTimeout(() => {
                            address.address1 = 'Demo location';
                            address.address2 = 'Westminster, London, TE5 7ER';
                            window.setTimeout(() => {
                                router.push({
                                    name: 'list',
                                    params: {
                                        id: podSearchId,
                                    }
                                });
                            }, 2 * 1000);
                        }, 1.1 * 1000);
                    }).catch(() => {
                        ConnectionChecker.failed(router, podSearchId);
                    });
                }
            });

            return {
                address
            };
        }
    });
</script>
