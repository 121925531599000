
    import { defineComponent } from 'vue';
    import BasketTile from "@/components/BasketTile.vue";
    import Language from "@/components/Language.vue";
    import Help from "@/components/Help.vue";
    import Basket from "@/stores/Basket";
    import Currency from "@/helpers/Currency";
    import {RouteLocationNormalizedLoaded, useRoute} from "vue-router";
    import Back from "@/components/Back.vue";

    export default defineComponent({
        components: {
            BasketTile,
            Back,
            Language,
            Help,
        },
        setup: () => {
            const route: RouteLocationNormalizedLoaded = useRoute();
            const podSearchId: string = route.params.podSearchId.toString();

            return {
                basket: Basket.state,
                price: Currency.price,
                podSearchId
            };
        },
    });
