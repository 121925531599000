
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            filter: Object,
            isActive: Boolean,
            filterClick: Function,
        }
    });
